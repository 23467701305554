@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~@quiqupltd/QuReactSDK/src/style.scss';
@import '~bootstrap/scss/bootstrap-grid.scss';
@import '~bootstrap/scss/custom';
@import '~bootstrap/scss/reboot';
@import '~@quiqupltd/QuReactSDK/src/styles/functions';
@import '~react-toastify/dist/ReactToastify.min';
@import '~react-dates/css/styles.scss';
@import '~react-responsive-carousel/lib/styles/carousel.min.css';

@import './functions.scss';
@import './variables.scss';
@import './base.scss';

.quiq-dash {
  display: flex;
  flex: 1;
  max-height: 100%;
  height: 100%;
  overflow: auto;

  .qu-menu {
    position: relative;
    z-index: getZIndex(qu-menu);
    flex-shrink: 0;
  }

  .main-toaster {
    position: fixed;
    z-index: 20;
    left: 50%;
    transform: translateX(-50%);
    top: 90px;
  }

  .qu-error {
    color: red;
  }

  .error {
    &-form {
      &-header {
        color: red;
        padding: 0.5rem 0;

        &.var-margin {
          margin-top: 2rem;
          margin-bottom: -2rem;
        }

        &.var-margin-right {
          margin-right: 2rem;
        }
      }

      &-container {
        outline: 2px dotted red;

        &.var-padding {
          padding: 0.2rem 1.2rem;
        }
      }
    }
  }
}

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,100;9..40,300;9..40,400;9..40,500;9..40,600;9..40,700;9..40,800;9..40,900;9..40,1000&display=swap');

* {
  font-feature-settings: 'ss01' on;
}

// increase specificity to override antd's and bootstrap's reset
html.quiqup {
  font-family: 'DM Sans', sans-serif;
  font-weight: 300;
  font-size: 62.5%;
  /*
  base font for PX to REM conversion is 10px (16 * 0.652 = 10).
  If you want a text to be 24px in REM, do 24/10 = 2.4rem.
  */
  height: 100%;
}

.quiqup {
  body {
    font-family: 'DM Sans', sans-serif;
    font-weight: 300;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: $background;
    font-size: $defaultFontSize;
    color: $brandSecondary;
    height: 100%;
    margin: 0;
  }

  #root {
    overflow: auto;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .ant-select-dropdown {
    .ant-select-item {
      color: $brandSecondary;
    }
    .ant-select-item-option-disabled {
      color: $brandSecondary;
      opacity: 0.2;
    }
  }
}
